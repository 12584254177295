<template>
	<v-container fluid tag="section">

		<ti-card :icon="$icons.target">
			<template #title>Benchmark Questions</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'BenchmarkQuestionsNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="benchmark_questions" item-key="name" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>

				</template>
				<template v-slot:item.expertise="{ item }">
					{{ expertiseLabel(item.expertise) }}
				</template>
				<template v-slot:item.answer="{ item }">
					{{ item[`option_${item.answer}`] }}
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import helperMixin from "../../mixins/helperMixin";

export default {
	name: "BenchmarkQuestionsIndex",
	mixins: [helperMixin],
	computed: {
		benchmark_questions: sync('benchmark_questions/benchmark_questions'),
		topics: sync('topics/topics'),

	},
	data: () => ({
		search: '',
		loading: false,
		headers: [
			{
				text: 'Topic',
				align: 'start',
				sortable: true,
				value: 'topic',
			},
			{
				text: 'Question',
				align: 'start',
				sortable: true,
				value: 'question',
			},
			{
				text: 'Answer',
				align: 'start',
				sortable: true,
				value: 'answer',
			},
			{
				text: 'Weight',
				align: 'start',
				sortable: true,
				value: 'weight',
			},
			{
				text: 'Expertise',
				align: 'start',
				sortable: true,
				value: 'expertise',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.loading = true;
			this.getBenchmarkQuestions();
			this.$store.set('topics/getTopics!', null);
			this.loading = false;
		},
		editItem(data) {
			this.$router.push({name: 'BenchmarkQuestionsEdit', params: {id: data.id}})
		},
		getBenchmarkQuestions() {
			this.$api.benchmark_questions.index()
				.then(response => {
					this.benchmark_questions = response.data
				})
				.catch(error => {
					this.$toast.add(error.message, 'error')
				})
		},
		filterOnlyCapsText(value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>